export default {
  "bank-accounts-show": "qgK",
  "header": "qgG l-app-content__page-header",
  "share-and-certificate": "qgr",
  "share": "qgb",
  "certificate": "qgy",
  "section-divider": "qgU",
  "content-wrapper": "qgj",
  "content": "qgW"
};
