export default {
  "page-wrapper": "qLC",
  "back-button": "qLk",
  "abort-button": "qLJ",
  "container": "qLs",
  "subtitle": "qLF",
  "button": "qLg",
  "panel-list": "qLD",
  "panel-list-item": "qLV",
  "card-illustration": "qLL"
};
