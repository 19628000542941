export default {
  "container": "qia",
  "content": "qiH",
  "setup-box": "qiO",
  "setup-wizard": "qim",
  "setup-success": "qip",
  "setup-success-lottie": "qix",
  "setup-close-btn": "qiw",
  "tray-connection": "qiN",
  "close-button": "qiv"
};
