export default {
  "page-wrapper": "qLU",
  "container": "qLj",
  "spinner-container": "qLW",
  "left-panel": "qLu",
  "form": "qTS",
  "left-content": "qTc",
  "form-footer": "qTq",
  "delete-button": "qTZ",
  "header": "qTR",
  "preview-container": "qTQ",
  "email-preview": "qTe",
  "close-button": "qTB btn btn--icon-only btn--tertiary btn--large"
};
