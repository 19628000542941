export default {
  "details": "qma",
  "header": "qmH",
  "avatar": "qmO mr-16",
  "header-beneficiary": "qmm",
  "header-beneficiary-activity": "qmp",
  "header-beneficiary-details": "qmx",
  "header-beneficiary-details-recurring": "qmw",
  "infos": "qmN",
  "infos-title": "qmv",
  "infos-empty": "qmo",
  "infos-list": "qmn",
  "infos-list-item": "qmi",
  "infos-list-item-left": "qmA",
  "infos-list-item-right": "qmY",
  "infos-list-item-attachment": "qmh",
  "infos-transactions": "qmf",
  "actions": "qmK"
};
