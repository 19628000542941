export default {
  "page-wrapper": "qgx",
  "container": "qgw",
  "sidebar": "qgN",
  "search-input": "qgv",
  "organizations-list": "qgo",
  "content": "qgn",
  "no-result": "qgi",
  "lottie-illustration": "qgA",
  "accounting-header": "qgY",
  "logo": "qgh",
  "accounting-title": "qgf"
};
