export default {
  "page": "qNz",
  "container": "qNa",
  "form-container": "qNH",
  "preview-container": "qNO",
  "pdf-preview-container": "qNm",
  "content": "qNp",
  "title": "qNx",
  "tabs-container": "qNw",
  "close-button": "qNN btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qNv",
  "header": "qNo",
  "without-tabs": "qNn"
};
