export default {
  "page-container": "qmR",
  "form": "qmQ",
  "close-button": "qme btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmB",
  "preview-padding": "qmE",
  "title": "qmd",
  "header": "qmI",
  "without-tabs": "qmt",
  "form-section": "qmM",
  "form-footer": "qmP",
  "disclaimer-text": "qml"
};
