export default {
  "wrapper": "qAc",
  "integrations": "qAq",
  "description": "qAZ body-2",
  "api": "qAR flex flex-column",
  "api-container": "qAQ flex large",
  "api-input": "qAe large",
  "api-btn": "qAB",
  "key-input": "qAE"
};
