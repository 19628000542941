export default {
  "multi-transfer": "qfY",
  "container": "qfh",
  "title": "qff",
  "subtitle": "qfK",
  "form-wrapper": "qfG",
  "form": "qfr",
  "form-title": "qfb",
  "files-buttons": "qfy",
  "files-buttons-item": "qfU",
  "selected": "qfj",
  "ebics-button": "qfW",
  "divider": "qfu"
};
