import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { PAY_LATER_TOPUP_FLOW_ORIGIN } from 'qonto/constants/financing';
import { switchingBankJsURL } from 'qonto/constants/hosts';
import { INVITATION_TYPES } from 'qonto/constants/membership';
import { CFT } from 'qonto/constants/sentry-tags';
import { TABS } from 'qonto/constants/supplier-invoice';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { getNRCIban } from 'qonto/utils/nrc';

export default class DeepLinksRoute extends Route {
  @service organizationManager;
  @service segment;
  @service router;
  @service abilities;
  @service subscriptionManager;
  @service upsellManager;
  @service flowLinkManager;
  @service beneficiariesManager;
  @service kycVerification;
  @service homePage;
  @service store;
  @service deviceManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);
  }

  async initializePricePlan() {
    if (!this.subscriptionManager.currentPricePlan) {
      await this.subscriptionManager.refresh();
    }
  }

  trackWithDistinctId(event, options) {
    let distinctId = this.organizationManager.membership.id;

    this.segment.identify({
      distinctId,
    });

    this.segment.track(event, options);
  }

  async redirect(_model, transition) {
    super.redirect(...arguments);

    let { queryParams } = transition.to;
    let {
      action,
      budget_id: budgetId,
      card_level: cardLevel,
      invoice_id: invoiceId,
      quote_id: quoteId,
      organization_slug: organizationSlug,
      origin,
      return_url: returnURL,
      target_currency: targetCurrency,
      transfer_id: transferId,
      upsell_extra,
    } = queryParams;
    let organization = organizationSlug
      ? this.organizationManager.getOrganizationBySlug(organizationSlug)
      : this.organizationManager.getDefaultOrganization();

    if (organization) {
      await this.organizationManager.setCurrentOrganizationAndMembership(organization);
    }

    //This MUST be on top in order to avoid check against missing organization
    if (action === 'switching_bank') {
      window.location.replace(returnURL ?? switchingBankJsURL);
    }

    if (action === 'cards.activate.flow') {
      return this.replaceWith('activate');
    }

    if (action === 'transfers.new') {
      await this.initializePricePlan();
      return this.redirectToTransferCreationPage();
    }

    if (action === 'transfers.multi.new') {
      await this.initializePricePlan();
      return this.redirectToMultiTransferCreationPage();
    }

    if (action === 'transfers.show') {
      await this.initializePricePlan();

      let transfer = await this.store.findRecord('transfer', transferId);

      if (!transfer) {
        return this.redirectToFrontpage();
      }

      this.router.replaceWith(
        transfer.isCompleted ? 'transfers.past' : 'transfers.pending',
        this.organizationManager.organization.slug,
        {
          queryParams: { highlight: transferId },
        }
      );
      return;
    }

    if (action === 'transfers.international-out') {
      let currency = targetCurrency?.toUpperCase() ?? null;
      return this.flowLinkManager.replaceWith({
        name: 'international-out',
        stepId: 'quote',
        queryParams: {
          ...(currency && { targetCurrency: currency }),
          ...(origin && { origin }),
        },
      });
    }

    if (action === 'nrc') {
      return this.redirectToCreateNRCTransfer(queryParams);
    }

    if (!organization) {
      return this.redirectToFrontpage();
    }

    let { kycAccepted, kycSubmitted } = this.organizationManager.membership;
    if (!kycAccepted || !kycSubmitted) {
      await this.kycVerification.generateUrl().catch(() => {
        // we don't want to block the user
      });
    }

    //with NO return ---
    if (action === 'missing_attachments' || action === 'transactions.missing_attachments') {
      this.replaceWith('transactions', organization.slug, {
        queryParams: {
          filter: 'missing-attachments',
          'bank-accounts': queryParams['bank_account_id'],
          'settled-at-from': queryParams['settled_at_from'],
          'only-me': queryParams['only_me'],
        },
      });
    } else if (action === 'statements.show') {
      queryParams = { ...queryParams, showStatement: true };
      this.replaceWith('accounts', organization.slug, { queryParams });
    } else if (action === 'cards.kyc.virtual') {
      let cardId = queryParams.card_id;

      this.replaceWith('cards.my-cards', organization.slug, {
        queryParams: {
          highlight: cardId,
        },
      });
    } else if (action === 'cards.renew.flow') {
      this.flowLinkManager.replaceWith({
        name: 'card-renew-physical',
        stepId: 'verify-delivery-address',
      });
    } else if (action === 'cards.upsell.flow') {
      let cardId = queryParams.card_id;

      this.segment.track('cards_upsell_opened', {
        card_id: cardId,
        origin: 'deeplink',
      });

      this.flowLinkManager.replaceWith({
        name: 'card-upsell',
        stepId: cardId ? 'upsell' : 'card-selection',
        queryParams: cardId ? { cardId, origin } : { origin },
      });
    } else if (action === 'cards.set_pin.kyc') {
      this.redirectToSetPinKYC(organization, queryParams);
    } else if (action === 'cards.show') {
      this.replaceWith('cards.show', organization.slug, { queryParams });
    } else if (action === 'settings.members.show') {
      this.trackWithDistinctId('members_show_deeplink', {
        origin: 'deeplink',
      });

      if (queryParams.membership_id) {
        let userAccessRoute = 'members.active.member';

        return this.replaceWith(userAccessRoute, organization.slug, queryParams.membership_id);
      } else {
        this.replaceWith('members', organization.slug, {
          queryParams,
        });
      }
    } else if (action === 'members.invite') {
      await this.initializePricePlan();
      this.trackWithDistinctId('members_invite_deeplink', {
        origin: 'deeplink',
      });
      this.flowLinkManager.replaceWith({ name: 'member-invite', queryParams });
    } else if (action === 'teams.show') {
      this.trackWithDistinctId('teams_show_deeplink', {
        origin: 'deeplink',
      });
      this.replaceWith('teams.index', organization.slug, { queryParams });
    } else if (action === 'budgets.show') {
      this.replaceWith('budgets.show', organization.slug, budgetId);
    } else if (action === 'subscription.show' || action === 'settings.subscription.show') {
      this.router.replaceWith('settings.price-plan.details', organization.slug, { queryParams });
    } else if (action === 'payment_methods.show') {
      this.router.replaceWith('settings.price-plan.payment-methods', organization.slug);
    } else if (action === 'subscription.change') {
      queryParams = { ...queryParams, upsellTrigger: upsell_extra };

      this.trackWithDistinctId('plan_upsell_deeplink', {
        upsell_extra,
        origin: 'deeplink',
      });

      if (upsell_extra) {
        await this.upsellManager.upgradeRecommendationWithTransition(upsell_extra);
      } else {
        this.redirectToUserProfile(organization, queryParams);
      }
    } else if (action === 'bank_account.show') {
      this.replaceWith('accounts', organization.slug, { queryParams });
    } else if (action === 'referral.show') {
      this.trackWithDistinctId('referral_show_deeplink', {
        origin: 'deeplink',
      });
      this.replaceWith('settings.referrals.show', organization.slug, { queryParams });
    } else if (action === 'checks.show') {
      this.trackWithDistinctId('checks', {
        origin: 'deeplink',
      });
      this.replaceWith('checks', organization.slug);
    } else if (action === 'cards.create.flow') {
      this.router.replaceWith('cards.physical-or-virtual', organization.slug);
    } else if (action === 'create_card.physical') {
      let queryParams = cardLevel === CARD_LEVELS.METAL ? { 'show-details': true } : {};
      this.flowLinkManager.replaceWith({
        name: 'card-new-physical',
        stepId: 'choose-card',
        queryParams,
      });
    } else if (action === 'create_card.virtual') {
      this.flowLinkManager.replaceWith({
        name: 'card-new-digital',
        stepId: 'choose-card',
      });
    } else if (action === 'supplier-list.list') {
      this.replaceWith('suppliers.index', organization.slug);
    } else if (action === 'transactions.show_comments') {
      if (
        variation('feature--boolean-modular-tables-full-release') ||
        variation('feature--boolean-modular-tables-external-release')
      ) {
        this.router.replaceWith('transactions', organization.slug, {
          queryParams: {
            query: queryParams['transaction_slug'],
          },
        });
      }

      this.trackWithDistinctId('transactions-modular-table', {
        origin: 'deeplink',
      });
    } else if (action === 'suggested_attachments' || action === 'transactions.show_suggestions') {
      this.router.replaceWith('transactions.index', organization.slug, {
        queryParams: {
          highlight: queryParams['transaction_id'],
          showSuggestedAttachments: true,
        },
      });
    } else if (action === 'transactions.show') {
      this.router.replaceWith(
        'transactions.show',
        organization.slug,
        queryParams['transaction_id']
      );
    } else if (action === 'transactions.index') {
      this.router.replaceWith('transactions.index', organization.slug);
    } else if (action === 'cashflow') {
      this.trackWithDistinctId('cashflow_deeplink', { origin: 'deeplink' });
      this.replaceWith('forecast', organization.slug);
    } else if (action === 'cashflow.management') {
      this.trackWithDistinctId('cashflow_management_deeplink', { origin: 'deeplink' });
      this.replaceWith('cash-flow-categories.index', organization.slug);
    } else if (action === 'dashboard') {
      this.trackWithDistinctId('dashboard_deeplink', {
        origin: 'deeplink',
      });
      this.replaceWith('overview', organization.slug);
    } else if (action === 'dashboard.help') {
      this.trackWithDistinctId('dashboard_help_deeplink', {
        origin: 'deeplink',
      });
      if (this.deviceManager.isMobile) {
        this.replaceWith('mobile-app-redirect', {
          queryParams: { openZendeskWidget: true },
        });
      } else {
        if (this.abilities.can('access accounting-hub')) {
          this.replaceWith('accounting-hub', {
            queryParams: { openZendeskWidget: true },
          });
        } else if (this.abilities.can('view overview')) {
          this.replaceWith('overview', organization.slug, {
            queryParams: { openZendeskWidget: true },
          });
        } else {
          this.replaceWith('settings', organization.slug, {
            queryParams: { openZendeskWidget: true },
          });
        }
      }
    } else if (action === 'mandates.new') {
      this.flowLinkManager.replaceWith({ name: 'mandate', stepId: 'landing' });
    } else if (action === 'mandates.list') {
      this.router.replaceWith('mandates', organization.slug);
    } else if (action === 'mandates.show') {
      if (queryParams['mandate_id']) {
        this.router.replaceWith(
          'mandates.list.mandate',
          organization.slug,
          queryParams['mandate_id']
        );
        // We receive some deeplinks with mandate.show without mandate_id query parameter
      } else {
        this.router.replaceWith('mandates', organization.slug);
      }
    } else if (action === 'accounting_discovery') {
      this.flowLinkManager.replaceWith({
        name: 'accounting-discovery',
        stepId: 'landing',
      });
    } else if (action === 'accounts.new') {
      this.replaceWith('accounts.new.index', organization.slug);
    } else if (action === 'accounts.new.current') {
      this.replaceWith('accounts.new.current', organization.slug);
    } else if (action === 'topups.show') {
      this.replaceWith('onboarding.topup.amount', organization.id);
    } else if (action === 'topups.payment_activation.show') {
      this.flowLinkManager.replaceWith({ name: 'payment-activation', stepId: 'context' });
    } else if (action === 'topups.pay_later_repayment.show') {
      this.flowLinkManager.replaceWith({
        name: 'pay-later-topup',
        stepId: 'amount',
        queryParams: {
          origin: PAY_LATER_TOPUP_FLOW_ORIGIN.DEEPLINK,
        },
      });
    } else if (action === 'request.show') {
      this.redirectToRequest(organization, queryParams);
    } else if (action === 'requests.pending.list' || action === 'requests.list_pending') {
      this.redirectToRequests(organization, queryParams);
    } else if (action === 'request.new') {
      this.redirectToNewRequest(organization);
    } else if (action === 'reimbursements.show') {
      this.redirectToReimbursements(organization);
    } else if (action === 'f24.show') {
      this.redirectToF24Show(organization.slug);
    } else if (action === 'nrc.show') {
      this.redirectToNrcShow(organization.slug);
      // TO DO: restore this deeplink when we work on PDP onboarding
      // } else if (action === 'einvoice-consent.show') {
      //   this.router.replaceWith('einvoicing-consent', organization.slug);

      //   this.trackWithDistinctId('Einvoicing-consent_screen_displayed', {
      //     origin: 'deeplink',
      //   });
    } else if (action === 'riba.show') {
      this.redirectToRibaShow(organization.slug);
    } else if (action === 'invoicing-settings') {
      this.router.replaceWith('invoicing-settings', organization.slug);
    } else if (action === 'quotes.list') {
      this.router.replaceWith('quotes.index', organization.slug);
    } else if (action === 'quotes.show') {
      this.router.replaceWith('quotes.show', organization.slug, quoteId);
    } else if (action === 'receivable-invoices.list') {
      let currentPricePlan = await this.subscriptionManager.getSubscriptionPricePlanFor(
        organization.slug
      );
      this.trackWithDistinctId('invoice', {
        origin: 'deeplink',
        price_plan: currentPricePlan?.code,
      });
      this.replaceWith('receivable-invoices.index', organization.slug);
    } else if (action === 'receivable-invoices.show') {
      let currentPricePlan = await this.subscriptionManager.getSubscriptionPricePlanFor(
        organization.slug
      );
      this.trackWithDistinctId('invoice_view', {
        origin: 'deeplink',
        price_plan: currentPricePlan?.code,
      });
      this.replaceWith('receivable-invoices.show', organization.slug, invoiceId);
    } else if (action === 'receivable-invoices.new') {
      let currentPricePlan = await this.subscriptionManager.getSubscriptionPricePlanFor(
        organization.slug
      );
      this.trackWithDistinctId('invoice-creation_deeplink', {
        origin: 'deeplink',
        price_plan: currentPricePlan?.code,
      });
      this.replaceWith('receivable-invoices.new', organization.slug);
    } else if (action === 'recurring-invoices.list') {
      this.replaceWith('invoice-subscriptions.index', organization.slug);
    } else if (action === 'recurring-invoices.new') {
      this.replaceWith('invoice-subscriptions.new', organization.slug);
    } else if (action === 'receipts_forward') {
      this.replaceWith('settings.receipts-forward', organization.slug);
    } else if (action === 'einvoice.activation') {
      this.replaceWith('einvoice-activation', organization.slug, {
        queryParams: { ...queryParams },
      });
    } else if (action === 'e-invoicing.settings') {
      this.replaceWith('settings.einvoicing', organization.slug);
      this.segment.track('menu_e-invoicing_clicked', { source: queryParams['source'] || 'other' });
    } else if (action === 'transfers.landing') {
      this.replaceWith('transfers.landing', organization.slug);
    } else if (action === 'accountant-access.invite') {
      await this.initializePricePlan();

      let stepId = this.subscriptionManager.hasReachedAccountantLimit
        ? 'plan-upgrade'
        : 'personal-info';

      queryParams.invitationType = INVITATION_TYPES.GUEST;

      this.flowLinkManager.replaceWith({ name: 'member-invite', stepId, queryParams });
    } else if (action === 'supplier-invoices.index') {
      let queryParams = { tab: TABS.INBOX };
      this.router.replaceWith('supplier-invoices.index', organization.slug, {
        queryParams,
      });
    } else if (action === 'supplier-invoices.list.due') {
      this.router.replaceWith('supplier-invoices.index', organization.slug, {
        queryParams: {
          dueDate: 'past_and_today',
        },
      });
    } else if (action === 'supplier-invoices.show') {
      let { origin } = queryParams;
      this.router.replaceWith('supplier-invoices.show', organization.slug, invoiceId, {
        queryParams: { origin },
      });

      this.segment.track('supplier_invoice_show_deeplink', {
        origin: 'deeplink',
      });
    } else if (action === 'insurance-hub.insurances.index') {
      this.router.replaceWith('insurance-hub.insurances.index', organization.slug);
    } else if (action === 'insurance-hub.policies') {
      this.router.replaceWith('insurance-hub.policies', organization.slug);
    } else if (action === 'qonto-hub.hub-application') {
      this.router.replaceWith(
        'settings.connect-hub.applications.hub-application',
        organization.slug,
        queryParams.application_slug
      );
    } else if (action === 'qonto-hub.filtered-view') {
      let { category_slug, stakeholder_slug } = queryParams;

      this.router.replaceWith('settings.connect-hub.categories', organization.slug, category_slug, {
        queryParams: {
          stakeholderSlug: stakeholder_slug,
        },
      });
    } else if (action === 'connect-hub.hub-application') {
      this.router.replaceWith(
        'settings.connect-hub.applications.hub-application',
        organization.slug,
        queryParams.application_slug
      );
    } else if (action === 'connect-hub.filtered-view') {
      let { category_slug, stakeholder_slug } = queryParams;

      this.router.replaceWith('settings.connect-hub.categories', organization.slug, category_slug, {
        queryParams: {
          stakeholderSlug: stakeholder_slug,
        },
      });
    } else if (action === 'connect-hub.search-view') {
      let { search_query } = queryParams;

      this.router.replaceWith('settings.connect-hub.applications', organization.slug, {
        queryParams: {
          searchQuery: search_query,
        },
      });
    } else if (action === 'connect-hub.collection-view') {
      let { collection_slug } = queryParams;

      this.router.replaceWith(
        'settings.connect-hub.collections',
        organization.slug,
        collection_slug
      );
    } else if (action === 'connect-hub.collection-filtered-view') {
      let { collection_slug, category_slug, stakeholder_slug } = queryParams;

      this.router.replaceWith(
        'settings.connect-hub.collections',
        organization.slug,
        collection_slug,
        {
          queryParams: {
            categorySlug: category_slug,
            stakeholderSlug: stakeholder_slug,
          },
        }
      );
    } else if (action === 'connect-hub.category-view') {
      let { category_slug } = queryParams;

      this.router.replaceWith('settings.connect-hub.categories', organization.slug, category_slug);
    } else if (action === 'users.profile.insurance') {
      this.router.replaceWith('settings.insurances', organization.slug);
    } else if (action === 'financing') {
      this.router.replaceWith('financing.partners', organization.slug);
    } else if (action === 'financing.offer') {
      if (queryParams.offer_slug) {
        this.router.replaceWith(
          'financing.partners.offer',
          organization.slug,
          queryParams.offer_slug
        );
      } else {
        this.router.replaceWith('financing.partners', organization.slug);
      }
    } else if (action === 'pay.later') {
      this.router.replaceWith('financing.pay-later', organization.slug, {
        queryParams: { origin: 'deeplink' },
      });
    } else if (action === 'pay.later.transfer') {
      this.flowLinkManager.replaceWith({
        name: 'pay-later-transfer',
        stepId: 'invoice-upload',
        queryParams: { origin: 'email' },
      });
    } else if (action === 'pagopa.payment') {
      this.router.replaceWith('pagopa', organization.slug);
    } else if (action === 'membership.update_role.kyc') {
      if (!this.organizationManager.membership.personalInfoProvided) {
        this.flowLinkManager.replaceWith({ name: 'management-kyc', stepId: 'user-info' });
      } else {
        if (this.organizationManager.membership.shouldSubmitKyc) {
          this.router.replaceWith('kyc.start', organization.slug);
        } else {
          this.replaceWith('transactions', organization.slug);
        }
      }
    } else if (action === 'membership.poi_renewal.fourthline') {
      this.router.replaceWith('kyc.start', organization.slug);
    } else if (action === 'flex_kyb.form') {
      this.router.replaceWith('flex-kyb', organization.slug);
    } else if (action === 'company_profile.show') {
      this.router.replaceWith('settings.company-profile', organization.slug);
    } else if (action === 'settings.appearance.show') {
      this.segment.track('appearance_settings_clicked', { origin: 'deeplink' });
      this.router.replaceWith('settings.personal.appearance', organization.slug);
    } else if (action === 'connections') {
      this.router.replaceWith('settings.connections', organization.slug, {
        queryParams: {
          highlight: queryParams['consent_bundle_id'],
        },
      });
    } else if (action === 'sdd_collection.show') {
      this.router.replaceWith('direct-debit-collections', organization.slug);
    } else if (action === 'upload-power-of-attorney.form') {
      this.router.replaceWith('upload-power-of-attorney', organization.slug);
    } else if (action === 'accounts.new.external') {
      this.flowLinkManager.replaceWith({ name: 'external-account-import', stepId: 'select' });
    } else if (action === 'client-list.list') {
      this.router.replaceWith('clients', organization.slug);
    } else if (action === 'client-list.new') {
      this.router.replaceWith('clients.new', organization.slug);
    } else if (action === 'client-list.import') {
      this.flowLinkManager.replaceWith({
        name: 'invoice-clients-import',
        stepId: 'initial',
        queryParams: { origin: 'deeplink' },
      });
    } else if (action === 'catalog-items.list') {
      this.router.replaceWith('products', organization.slug);
    } else if (action === 'catalog-items.create') {
      this.router.replaceWith('products.new', organization.slug);
    } else if (action === 'products-list.import') {
      this.flowLinkManager.replaceWith({
        name: 'invoice-products-import',
        stepId: 'upload',
        queryParams: { origin: 'deeplink' },
      });
    } else if (action === 'approval_workflows.list') {
      this.router.replaceWith('settings.approval-workflows', organization.slug);
    } else if (action === 'subscription.add_on.manage') {
      this.flowLinkManager.replaceWith({
        name: 'addon-change',
        stepId: 'addons',
        queryParams: { origin: 'deeplink' },
      });
    } else if (action === 'connect-hub.hub-application.setup') {
      this.router.replaceWith(
        'settings.connect-hub.applications.hub-application.setup',
        organization.slug,
        queryParams['integration_slug']
      );
    } else if (action === 'compass') {
      this.router.replaceWith('upcoming-transactions', organization.slug);
    } else if (action === 'payments_acceptance.onboard') {
      this.router.replaceWith('card-acquirer.payments', organization.slug, {
        queryParams: { 'show-modal': true },
      });
    } else {
      this.replaceWith('organizations.show', organization.slug);
    }
  }

  redirectToNewRequest(organization) {
    let canReviewTransfers = this.abilities.can('review transfer request');
    let canReviewCards = this.abilities.can('review card requests');

    if (canReviewCards && !canReviewTransfers) {
      this.replaceWith('requests.landing', organization.slug, {
        queryParams: { origin: 'reimbursements' },
      });
      return;
    }
    if (canReviewTransfers && !canReviewCards) {
      this.replaceWith('requests.landing', organization.slug, { queryParams: { origin: 'cards' } });
      return;
    }

    this.replaceWith('requests.landing', organization.slug, {
      queryParams: { origin: 'requests' },
    });
  }

  redirectToRequest(organization, queryParams) {
    let canReviewTransfers = this.abilities.can('review transfer request');
    let canReviewCards = this.abilities.can('review card requests');
    let requestId = queryParams.request_id || queryParams.highlight;

    if (!canReviewTransfers && !canReviewCards) {
      this.replaceWith('requests.index', organization.slug, {
        queryParams,
        highlight: requestId,
      });
      return;
    }

    this.replaceWith('tasks.index', organization.slug, {
      queryParams,
    });
  }

  redirectToRequests(organization) {
    this.replaceWith('tasks.pending', organization.slug);
  }

  redirectToReimbursements(organization) {
    return this.replaceWith('reimbursements', organization.slug);
  }

  redirectToFrontpage() {
    this.replaceWith('protected');
  }

  redirectToUserProfile(organization, queryParams) {
    if (this.abilities.cannot('view subscription')) {
      this.replaceWith('settings', organization.slug);
    } else {
      this.flowLinkManager.replaceWith({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams,
      });
    }
  }

  redirectToTransferCreationPage() {
    let origin = TRANSFER_FLOW_ORIGIN.NEW_DEEPLINK;
    let canCreateTransfer = this.abilities.can('create transfer');

    if (canCreateTransfer) {
      return this.flowLinkManager.replaceWith({
        name: 'sepa-transfer',
        stepId: 'beneficiaries',
        queryParams: { origin },
      });
    }

    return this.replaceWith('requests.transfers.new', this.organizationManager.organization);
  }

  redirectToMultiTransferCreationPage() {
    let canCreateTransfer = this.abilities.can('create transfer');
    let targetRoute = canCreateTransfer ? 'transfers.multi.new' : 'requests.transfers.multi.new';
    return this.router.replaceWith(targetRoute, this.organizationManager.organization);
  }

  redirectToF24Show(slug) {
    this.replaceWith('f24.list.upcoming', slug);
  }

  redirectToNrcShow(slug) {
    this.replaceWith('agencia-tributaria', slug);
  }

  redirectToRibaShow(slug) {
    this.replaceWith('riba', slug);
  }

  async redirectToCreateNRCTransfer(queryParams) {
    let { amount, company_type, name, reference, tax_model } = queryParams;

    let initialStepId = await this.getNRCTransferInitialStepId(queryParams);

    this.flowLinkManager.replaceWith({
      name: 'sepa-transfer',
      stepId: initialStepId,
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.NRC,
        amount,
        taxModel: tax_model,
        reference,
        companyType: company_type,
        taxBeneficiaryName: name,
      },
    });
  }

  async getNRCTransferInitialStepId(queryParams) {
    let { name } = queryParams;

    let iban = getNRCIban(name);

    if (iban) {
      let beneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        this.organizationManager.organization.id,
        iban
      );
      if (beneficiary) return 'details';
    }

    return 'add-tax-beneficiary';
  }

  redirectToSetPinKYC(organization, queryParams) {
    let cardId = queryParams.card_id;

    this.segment.track('physical_card_set_pin_start');

    let flowName = 'card-set-pin';
    let flowStepId = 'set-pin';

    this.replaceWith('flows', organization.slug, flowName, flowStepId, {
      queryParams: { id: cardId },
    });
  }
}
