export default {
  "page": "qvL",
  "container": "qvT",
  "form-container": "qvz",
  "preview-container": "qva",
  "pdf-preview-container": "qvH",
  "content": "qvO",
  "title": "qvm",
  "tabs-container": "qvp",
  "close-button": "qvx btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvw",
  "header": "qvN",
  "without-tabs": "qvv"
};
