export default {
  "container": "qNS",
  "form-container": "qNc",
  "title": "qNq",
  "title-border": "qNZ",
  "tabs-container": "qNR",
  "preview-container": "qNQ",
  "email-preview-container": "qNe",
  "close-button": "qNB btn btn--icon-only btn--tertiary btn--large"
};
