export default {
  "page": "qwD",
  "container": "qwV",
  "form-container": "qwL",
  "preview-container": "qwT",
  "pdf-preview-container": "qwz",
  "content": "qwa",
  "header": "qwH",
  "close-button": "qwO btn btn--icon-only btn--tertiary btn--large"
};
