export default {
  "mt-16": "qnZ",
  "title": "qnR",
  "avatar": "qnQ",
  "edit-organization-form": "qne",
  "dropdown-content": "qnB",
  "city-block": "qnE",
  "zip-code-input": "qnd",
  "cancel-button": "qnI",
  "error-message": "qnt",
  "nature-of-operations": "qnM",
  "error": "qnP",
  "tax-country": "qnl",
  "tooltip": "qnX",
  "legal-code": "qnC",
  "activity-type": "qnk"
};
