export default {
  "container": "qnU",
  "link-grey": "qnj",
  "slack-section-title": "qnW title-4 mb-8",
  "edit-channel-text": "qnu body-2 mb-16",
  "rule-section-title": "qiS title-4 mb-16",
  "rule-cards-container": "qic",
  "edit-slack-container": "qiq",
  "rule-card": "qiZ",
  "add-rule-container": "qiR",
  "add-rule-link-button": "qiQ",
  "add-rule-text": "qie",
  "empty-state": "qiB",
  "empty-state-textblock": "qiE",
  "empty-state-title": "qid title-2",
  "empty-state-description": "qiI body-2",
  "empty-state-illustration": "qit",
  "rules": "qiM"
};
