export default {
  "container": "qAU",
  "security-method": "qAj",
  "icon": "qAW",
  "body": "qAu",
  "status": "qYS body-2",
  "device": "qYc",
  "device-icon": "qYq",
  "subtitle": "qYZ body-2",
  "current": "qYR"
};
