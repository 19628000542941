export default {
  "bank-accounts": "qon l-app-content__wrapper",
  "header-wrapper": "qoi",
  "scrolled-top": "qoA",
  "header": "qoY l-app-content__page-header",
  "create-account-button": "qoh btn btn--primary",
  "main": "qof",
  "accounts": "qoK",
  "subtitle-container": "qoG",
  "subtitle": "qor title-3 mb-16",
  "amount": "qob body-2",
  "list--cashbeeSavings": "qoy"
};
