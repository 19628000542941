export default {
  "body": "qpM",
  "members": "qpP",
  "members-results": "qpl",
  "members-list": "qpX",
  "members-title": "qpC caption-bold",
  "member": "qpk",
  "empty-illustration": "qpJ",
  "empty-title": "qps title-3",
  "empty-description": "qpF body-2",
  "invitable-members": "qpg",
  "details": "qpD",
  "pagination-footer": "qpV"
};
