export default {
  "signin": "qhc",
  "form": "qhq",
  "illustration": "qhZ",
  "purple": "qhR",
  "mint": "qhQ",
  "mustard": "qhe",
  "peach": "qhB",
  "form-wrapper": "qhE",
  "header-logo": "qhd",
  "links": "qhI",
  "no-account-label": "qht",
  "spinner-container": "qhM",
  "main-image-container": "qhP",
  "main-image": "qhl",
  "signin-illustration": "qhX"
};
