export default {
  "page": "qwE",
  "container": "qwd",
  "form-container": "qwI",
  "preview-container": "qwt",
  "pdf-preview-container": "qwM",
  "content": "qwP",
  "header": "qwl",
  "close-button": "qwX btn btn--icon-only btn--tertiary btn--large"
};
