export default {
  "container": "qnx",
  "wrapper": "qnw",
  "grey-header-block": "qnN",
  "connect-app-header": "qnv",
  "connect-app-header-link": "qno",
  "connect-app-header-content": "qnn",
  "connect-app-header-content-default": "qni",
  "connect-app-header-content-left": "qnA",
  "button-block": "qnY",
  "connect-app-body": "qnh",
  "connect-app-body-left-content": "qnf",
  "connect-app-body-sidebar": "qnK"
};
