export default {
  "header": "qnJ",
  "content": "qns",
  "read-only": "qnF",
  "disclaimer": "qng",
  "title": "qnD",
  "card-container": "qnV",
  "representatives-section": "qnL",
  "disabled-href": "qnT"
};
