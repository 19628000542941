export default {
  "container": "qYT",
  "header-cell": "qYz caption-bold",
  "col-8": "qYa",
  "table": "qYH",
  "cell": "qYO body-2",
  "cell-content": "qYm",
  "row": "qYp",
  "empty": "qYx",
  "quick-actions": "qYw",
  "card-infos": "qYN",
  "card-digits": "qYv",
  "empty-state": "qYo"
};
