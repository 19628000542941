export default {
  "member-details": "qaL",
  "header": "qaT",
  "header-img": "qaz",
  "invited-persona": "qaa",
  "revoked-persona": "qaH",
  "invited-icon": "qaO",
  "revoked-icon": "qam",
  "dropdown": "qap",
  "header-body": "qax",
  "name": "qaw title-3",
  "email": "qaN caption-bold",
  "actions": "qav body-2",
  "actions-cards": "qao",
  "actions-transactions": "qan",
  "body": "qai",
  "expense-permissions-section": "qaA",
  "body-title": "qaY title-4",
  "body-attr": "qah",
  "body-label": "qaf",
  "attr-buttons": "qaK",
  "body-role": "qaG small",
  "details-actions": "qar",
  "actions-item": "qab",
  "disclaimer": "qay",
  "permissions": "qaU",
  "permission": "qaj",
  "permission-label": "qaW",
  "permission-check": "qau",
  "permission-missing": "qHS",
  "editable-section": "qHc"
};
