export default {
  "page-wrapper": "qhC",
  "content-wrapper": "qhk",
  "content": "qhJ",
  "period-field": "qhs",
  "format-options": "qhF",
  "format-option": "qhg",
  "format-radio": "qhD",
  "format-description": "qhV body-2"
};
