export default {
  "page-container": "qOY",
  "form": "qOh",
  "close-button": "qOf btn btn--icon-only btn--tertiary btn--large",
  "preview": "qOK",
  "preview-padding": "qOG",
  "title": "qOr",
  "header": "qOb",
  "without-tabs": "qOy",
  "form-section": "qOU",
  "form-footer": "qOj",
  "disclaimer-text": "qOW"
};
