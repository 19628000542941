export default {
  "header": "qgk",
  "btn-preset": "qgJ btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qgs",
  "statements-table": "qgF",
  "no-result": "qgg",
  "lottie-illustration": "qgD",
  "wrapper": "qgV",
  "access-denied": "qgL",
  "access-denied-illustration": "qgT",
  "application-card": "qgz",
  "presets-wrapper": "qga",
  "error-wrapper": "qgH",
  "error-container": "qgO",
  "error-illustration": "qgm mb-32",
  "invoicing-info": "qgp"
};
