export default {
  "guests": "qHq",
  "guests--upsell-screen": "qHZ",
  "no-members": "qHR",
  "teams-header": "qHQ",
  "empty": "qHe",
  "invite-text": "qHB",
  "body": "qHE",
  "members": "qHd",
  "members-list": "qHI",
  "members-title": "qHt caption-bold",
  "member": "qHM",
  "details": "qHP",
  "kyc-disclaimer": "qHl",
  "kyc-disclaimer-width": "qHX"
};
