import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MobileAppRedirectRoute extends Route {
  @service zendeskWidget;
  queryParams = {
    openZendeskWidget: {
      refreshModel: true,
    },
  };

  model({ openZendeskWidget }) {
    if (openZendeskWidget === 'true') {
      this.zendeskWidget.openAfterMounting();
    }
  }
}
