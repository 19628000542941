import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { KybPendingModal } from 'qonto/react/components/pagopa/kyb-pending-modal';

export default class PagopaIndexController extends Controller {
  @service flowLinkManager;
  @service intl;
  @service organizationManager;
  @service segment;
  @service router;
  @tracked showKybPendingModal = false;

  Modal = KybPendingModal;

  @action
  handlePopupClose() {
    this.showKybPendingModal = false;
  }

  @action
  handleEmptyStateClick() {
    let { organization, membership } = this.organizationManager;
    if (organization.kybAccepted || (membership.shouldSubmitKyc && organization.kybPending)) {
      this.segment.track('pagopa_section_start-button_clicked');
      this.flowLinkManager.transitionTo({
        name: 'pagopa',
        stepId: 'notice-info',
      });
    } else {
      this.showKybPendingModal = true;
    }
  }

  get emptyStateOptions() {
    return {
      title: this.intl.t('pagopa.section.empty-state.title'),
      subtitle: this.intl.t('pagopa.section.empty-state.subtitle'),
      lottieSrc: `/lotties/pagopa/empty-state.json`,
      button: {
        label: this.intl.t('pagopa.section.empty-state.cta'),
        callback: this.handleEmptyStateClick,
      },
    };
  }
}
