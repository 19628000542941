export default {
  "title": "qAo title-3",
  "personal-profile-container": "qAn",
  "personal-profile-section": "qAi",
  "member-info-title": "qAA",
  "kyc-complete-profile": "qAY",
  "info-fields": "qAh",
  "field": "qAf",
  "address-fields": "qAK",
  "field-span": "qAG",
  "input": "qAr",
  "cancel": "qAb"
};
