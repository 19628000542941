export default {
  "container": "qiP",
  "wrapper": "qil",
  "grey-header-block": "qiX",
  "connect-app-header": "qiC",
  "connect-app-header-link": "qik",
  "connect-disclaimer-wrapper": "qiJ",
  "connect-app-header-content": "qis",
  "connect-app-header-cta": "qiF",
  "connect-app-content": "qig",
  "connect-app-description": "qiD",
  "connect-app-body": "qiV"
};
