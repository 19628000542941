export default {
  "container": "qpu",
  "content": "qxS",
  "logos": "qxc",
  "logo": "qxq",
  "animation": "qxZ",
  "title": "qxR title-2 mb-32",
  "avatar": "qxQ",
  "actions": "qxe mb-32",
  "footer": "qxB body-2"
};
