export default {
  "container": "qHu",
  "caption": "qOS caption",
  "compact-header": "qOc",
  "visible": "qOq",
  "page-container": "qOZ",
  "main": "qOR",
  "tabs": "qOQ",
  "tabs-nav": "qOe",
  "tabs-panels": "qOB",
  "tabs-panel": "qOE body-2",
  "sidebar": "qOd",
  "cms-content": "qOI"
};
