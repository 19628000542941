export default {
  "page": "qwA",
  "container": "qwY",
  "form-container": "qwh",
  "preview-container": "qwf",
  "pdf-preview-container": "qwK",
  "content": "qwG",
  "header": "qwr",
  "close-button": "qwb btn btn--icon-only btn--tertiary btn--large"
};
