export default {
  "mandates": "qmG",
  "mandates-empty": "qmr",
  "header": "qmb",
  "header-empty": "qmy",
  "header-content": "qmU",
  "search": "qmj",
  "search-bar": "qmW",
  "body": "qmu",
  "isEmpty": "qpS",
  "left-section": "qpc",
  "mandates-list": "qpq",
  "mandate-suspended": "qpZ",
  "list-title": "qpR caption-bold",
  "list-empty": "qpQ",
  "empty-illustration": "qpe",
  "empty-title": "qpB title-3",
  "empty-description": "qpE body-2",
  "body-details": "qpd",
  "l-app-content__page-header": "qpI",
  "pagination-footer": "qpt"
};
