export default {
  "main-container": "qLc",
  "main": "qLq",
  "main-wrapper": "qLZ",
  "close-button": "qLR",
  "active-block": "qLQ",
  "title": "qLe",
  "list-options": "qLB",
  "card": "qLE",
  "card-container": "qLd",
  "card-image": "qLI",
  "card-tiles": "qLt"
};
