export default {
  "container": "qvf",
  "form-container": "qvK",
  "title": "qvG",
  "title-border": "qvr",
  "tabs-container": "qvb",
  "preview-container": "qvy",
  "email-preview-container": "qvU",
  "close-button": "qvj btn btn--icon-only btn--tertiary btn--large"
};
