export default {
  "accounts": "qDQ",
  "header-subtitle": "qDe title-4",
  "subtitle-container": "qDB",
  "subtitle": "qDE title-3 mb-16",
  "amount": "qDd body-2",
  "recommendations-section": "qDI",
  "dismissing": "qDt",
  "recommendations-divider": "qDM",
  "list--cashbeeSavings": "qDP",
  "list": "qDl",
  "icon": "qDX",
  "badge": "qDC",
  "discover-tile": "qDk"
};
