export default {
  "container": "qNd",
  "form-container": "qNI",
  "preview-container": "qNt",
  "credit-note-preview-container": "qNM",
  "credit-note-preview": "qNP",
  "content": "qNl",
  "title": "qNX",
  "tabs-container": "qNC",
  "close-button": "qNk btn btn--icon-only btn--tertiary btn--large",
  "header": "qNJ",
  "without-tabs": "qNs"
};
