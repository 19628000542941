export default {
  "vat-number": "qAl body-2",
  "banner": "qAX",
  "container": "qAC",
  "organization-profile": "qAk",
  "input": "qAJ",
  "medium-input": "qAs medium",
  "small-input": "qAF small",
  "actions": "qAg",
  "company-info": "qAD",
  "company-info-section": "qAV",
  "company-address-section": "qAL",
  "company-country": "qAT",
  "company-billing-email": "qAz",
  "company-address": "qAa"
};
