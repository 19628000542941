export default {
  "splash-landing": "qVo",
  "container": "qVn",
  "options": "qVi",
  "physical": "qVA",
  "card-label": "qVY",
  "physical-cta-container": "qVh",
  "physical-cta": "qVf",
  "coming-soon": "qVK"
};
