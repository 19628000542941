export default {
  "container": "qxE",
  "content": "qxd",
  "logos": "qxI",
  "logo": "qxt",
  "animation": "qxM",
  "title": "qxP title-2 mb-32",
  "avatar": "qxl",
  "dropdown": "qxX",
  "disabled": "qxC",
  "organization": "qxk",
  "actions": "qxJ"
};
