export default {
  "page-header": "qKl",
  "header-breadcrumb": "qKX",
  "tabs-container": "qKC",
  "bottom-border": "qKk",
  "tabs-separator": "qKJ",
  "previous-request": "qKs",
  "body-link": "qKF",
  "border-top": "qKg"
};
