export default {
  "team": "qpL",
  "no-members": "qpT",
  "teams-header": "qpz",
  "header-search": "qpa",
  "search-bar": "qpH",
  "invite": "qpO body-2",
  "invite-text": "qpm",
  "empty-illustration": "qpp",
  "empty-title": "qpx title-3",
  "empty-description": "qpw body-2",
  "pagination-footer": "qpN",
  "filter-search": "qpv",
  "fullsize": "qpo",
  "tabs-container": "qpn",
  "tabs-separator": "qpi",
  "tooltip-wrapper": "qpA",
  "invite-cta": "qpY",
  "kyc-disclaimer": "qph",
  "kyc-disclaimer-width": "qpf"
};
