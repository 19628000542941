export default {
  "supplier-invoices-page": "qhT",
  "supplier-invoices-page-header": "qhz",
  "filters-container": "qha",
  "search-bar": "qhH",
  "filters": "qhO",
  "bulk-actions": "qhm",
  "item-count": "qhp",
  "higher-index": "qhx",
  "title-wrapper": "qhw",
  "title": "qhN",
  "subtitle": "qhv",
  "table-container": "qho",
  "file-dropzone": "qhn",
  "dropzone-visible": "qhi",
  "header-actions": "qhA",
  "header-wrapper": "qhY",
  "disclaimer-wrapper": "qhh"
};
