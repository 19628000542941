export default {
  "container": "qzA",
  "page-wrapper": "qzY",
  "header": "qzh",
  "back-button-wrapper": "qzf",
  "header-inner": "qzK",
  "header-main-wrapper": "qzG",
  "header-main": "qzr",
  "header-right": "qzb",
  "content-wrapper": "qzy",
  "content": "qzU",
  "content-body": "qzj",
  "sidebar": "qzW"
};
