export default {
  "dropdown-icon": "qfX",
  "dropdown": "qfC",
  "download-button": "qfk",
  "connections-banner": "qfJ",
  "recommendation-cards": "qfs",
  "wrapper-with-cards": "qfF",
  "transactions-wrapper": "qfg",
  "separator": "qfD",
  "with-transaction": "qfV",
  "annual-billing-banner": "qfL",
  "mt-132": "qfT",
  "transactions-sidebar": "qfz",
  "empty-state-wrapper": "qfa",
  "wrapper-with-modular": "qfH",
  "modular-table-container": "qfO",
  "modular-table-sidebar": "qfm",
  "modular-table-no-overlay": "qfp",
  "sidebar-overlay": "qfx",
  "overlay-visible": "qfw"
};
